import React, { forwardRef, useContext } from "react";
import { AppContext } from "../context/AppContext";
import dayjs from "dayjs";
import { formatDate, formatDateDay } from "../utils/helper";
import { numberFormatBRL } from "./InvestmentId";
import "../utils/trade.css";
import { formatBTCString } from "../utils/Helpers/btcFormat";
import { useTranslation } from 'react-i18next';
import '../i18n';

const InvestmentPDF = forwardRef((props, ref) => {
  const { btcPrice } = useContext(AppContext);
  const { ranges, investment } = props;
  const { t } = useTranslation();

  const findRentability = (range) => {
    const filtered = range.dailyReports.filter((dr) => dr.appliedProfit === true);
    if (filtered.length !== 0) {
      return filtered[filtered.length - 1];
    } else {
      return null;
    }
  }

  const getLastMonthValue = (ranges, i, initialAmount) => {
    if (i !== 0) {
      const lastRange = ranges[i - 1];
      const dr = lastRange?.dailyReports[lastRange?.dailyReports?.length - 1];
      let finalAmount;
      if (dr === undefined) {
        finalAmount = initialAmount;
      } else {
        finalAmount = dr?.finalAmount;
      }
      return (finalAmount);
    } else return null;
  }

  return (
    <div id="pdf" ref={ref} style={{ display: "none" }}>
      <div className="reports-trade-master ">
        <main className="main-investmentList">
          {/* Informações Gerais */}
          <div className="balances pdf-top" style={{ marginBottom: '0px' }}>
            <h2 className="margins">
              {t("generalinformation")}
            </h2>
            <div className="balance">
              <div className="balance-data" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>
                  {formatDate(investment.createdAt)} - {t("initialinvestment")}
                </span>
                <span>
                  {formatBTCString((investment.initialAmount) / btcPrice)}
                </span>
              </div>
              <div className="refferences" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span className="refferences" style={{ color: "#50504E", fontWeight: 400 }}>
                  {t("refference")} (R$):
                </span>
                <span className="refferences" style={{ color: "#50504E", fontWeight: 400 }}>
                  {numberFormatBRL(investment.initialAmount)}
                </span>
              </div>
              <div className="balance-data">
                <span>{t("creationdate")}</span>
                <span>
                  {formatDate(investment.createdAt)}
                </span>
              </div>
              <div className="balance-data">
                <span>{t("period")}</span>
                <span>
                  {investment.period} {investment.period > 1 ? t("months") : t("month")}
                </span>
              </div>
              <div className="balance-data">
                <span>{t("fundbday")}</span>
                <span>
                  {t("day")} {formatDateDay(investment.createdAt)}
                </span>
              </div>
              <div className="balance-data">
                <span>{t("bitcoinquotation")}-{dayjs().format("DD/MM/YYYY")}</span>
                <span>
                  {numberFormatBRL(btcPrice)}
                </span>
              </div>
            </div>
          </div>

          {/* Informações Mensais */}
          {ranges && (
            <div className="balances">
              {ranges?.map((range, i) => (
                dayjs(range.start).isSameOrBefore(dayjs()) && (
                  <div key={`range-${i}`} className="monthly-balance" style={{ marginBottom: '30px', borderBottom: '2px solid #ceba85', paddingBottom: '15px', marginTop: '200px' }}>
                    <h2 className="inv-id-months" style={{ fontWeight: 'bold' }}>
                      {t("month")} {i} - <span id="inv-id-month-range" style={{ fontStyle: 'italic' }}>
                        {t("from")} {formatDate(range.start)} {t("to")} {formatDate(range.end)}
                      </span>
                    </h2>

                    {/* Saldo Inicial do Mês */}
                    <div className="refferences" style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                      <span className="refference">{formatDate(range.start)} - {t("initialbalancemonth")}:</span>
                      {(i === 0 && range.dailyReports.length === 0) && (
                        <span className="refferences">
                          {formatBTCString((investment.initialAmount) / btcPrice)}
                        </span>
                      )}
                      {(i === 0 && range.dailyReports.length !== 0) && (
                        <span className="refferences">
                          {formatBTCString((range.dailyReports[0]?.initialAmount) / btcPrice)}
                        </span>
                      )}
                      {(i !== 0 && range.dailyReports.length > 0) && (
                        <span className="refferences">
                          {formatBTCString((getLastMonthValue(ranges, i, investment.initialAmount)) / btcPrice)}
                        </span>
                      )}
                      {(i !== 0 && range.dailyReports.length === 0) && (
                        <span className="refferences">
                          {formatBTCString((getLastMonthValue(ranges, i, investment.initialAmount)) / btcPrice)}
                        </span>
                      )}
                    </div>

                    {/* Referência do Saldo Inicial */}
                    <div className="refferences" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {(i === 0 && range.dailyReports.length === 0) && (
                        <>
                          <span className="refferences">
                            {t("refference")} (R$):
                          </span>
                          <span className="refferences">
                            {numberFormatBRL(investment.initialAmount)}
                          </span>
                        </>
                      )}
                      {(i === 0 && range.dailyReports.length !== 0) && (
                        <>
                          <span className="refferences">
                            {t("refference")} (R$):
                          </span>
                          <span className="refferences">
                            {numberFormatBRL(range.dailyReports[0]?.initialAmount)}
                          </span>
                        </>
                      )}
                      {(i !== 0 && range.dailyReports.length > 0) && (
                        <>
                          <span className="refferences">
                            {t("refference")} (R$):
                          </span>
                          <span className="refferences">
                            {numberFormatBRL(getLastMonthValue(ranges, i, investment.initialAmount))}
                          </span>
                        </>
                      )}
                      {(i !== 0 && range.dailyReports.length === 0) && (
                        <>
                          <span className="refferences">
                            {t("refference")} (R$):
                          </span>
                          <span className="refferences">
                            {numberFormatBRL(getLastMonthValue(ranges, i, investment.initialAmount))}
                          </span>
                        </>
                      )}
                    </div>

                    {/* Eventos do Mês (dailyReports) */}
                    {range.dailyReports?.map((dr, drIndex) => {
                      const rentability = findRentability(range);
                      return (
                        <div key={`dr-${drIndex}`} style={{ marginTop: '10px' }}>
                          {/* Saques */}
                          {dr.withdraws.length > 0 && dr.withdraws.map((w, wIndex) => w.status === 'approved' && (
                            <div key={`w-${wIndex}`} style={{ marginTop: '10px' }}>
                              {w.type === 'liquidation' && (
                                <>
                                  <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                                    <span>{formatDate(w.updatedAt)} - {t("totalAmount")}:</span>
                                    <span>
                                      {formatBTCString((dr.initialAmount) / btcPrice)}
                                    </span>
                                  </div>
                                  <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>{t("refference")} (R$):</span>
                                    <span>{numberFormatBRL(dr.initialAmount)}</span>
                                  </div>
                                </>
                              )}
                              <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                                <span>{formatDate(w.updatedAt)} - {t("withdrawt")} {w.type === 'anticipated' ? t("anticipated") : w.type === 'liquidation' ? t("liquidate") : t("profit")}:</span>
                                <span>{formatBTCString((w.totalAmount) / btcPrice)}</span>
                              </div>
                              <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{t("refference")} (R$):</span>
                                <span>{numberFormatBRL(w.totalAmount)}</span>
                              </div>
                              <div className="inv-id-statement internal-statement" style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '15px', marginRight: '15px' }}>
                                <span>{t("operationalfee")}:</span>
                                <span>{formatBTCString((w.operatingFeeAmount) / btcPrice)}</span>
                              </div>
                              <div className="inv-id-statement internal-statement" style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '15px', marginRight: '15px' }}>
                                <span>{t("refference")} (R$):</span>
                                <span>{numberFormatBRL(w.operatingFeeAmount)}</span>
                              </div>
                              {w.type === 'anticipated' && (
                                <>
                                  <div className="inv-id-statement internal-statement" style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '15px', marginRight: '15px' }}>
                                    <span>{t("prorata")}:</span>
                                    <span>{formatBTCString((w.proRataAmount) / btcPrice)}</span>
                                  </div>
                                  <div className="inv-id-statement internal-statement" style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '15px', marginRight: '15px' }}>
                                    <span>{t("refference")} (R$):</span>
                                    <span>{numberFormatBRL(w.proRataAmount)}</span>
                                  </div>
                                  <div className="inv-id-statement internal-statement" style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '15px', marginRight: '15px' }}>
                                    <span>{t("penalty")}:</span>
                                    <span>{formatBTCString((w.penaltyAmount) / btcPrice)}</span>
                                  </div>
                                  <div className="inv-id-statement internal-statement" style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '15px', marginRight: '15px' }}>
                                    <span>{t("refference")} (R$):</span>
                                    <span>{numberFormatBRL(w.penaltyAmount)}</span>
                                  </div>
                                </>
                              )}
                              <div className="inv-id-statement internal-statement" style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '15px', marginRight: '15px' }}>
                                <span>{t("solicitedvalue")}:</span>
                                <span>{formatBTCString((w.requestedAmount) / btcPrice)}</span>
                              </div>
                              <div className="inv-id-statement internal-statement" style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '15px', marginRight: '15px' }}>
                                <span>{t("refference")} (R$):</span>
                                <span>{numberFormatBRL(w.requestedAmount)}</span>
                              </div>
                            </div>
                          ))}

                          {/* Depósitos */}
                          {dr.deposits.length > 0 && dr.deposits.map((d, dIndex) => (
                            <div key={`d-${dIndex}`} style={{ marginTop: '10px' }}>
                              <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                                <span>{formatDate(d.createdAt)} - {t("deposit")}:</span>
                                <span>{formatBTCString((d.amount) / btcPrice)}</span>
                              </div>
                              <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{t("refference")} (R$):</span>
                                <span>{numberFormatBRL(d.amount)}</span>
                              </div>
                            </div>
                          ))}

                          {/* Rentabilidade */}
                          {dr.appliedProfit && rentability && (
                            <div style={{ marginTop: '10px' }}>
                              <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                                <span>
                                  {formatDate(rentability.date)} - {t("profit")} ({(rentability.profitPercentage * 100).toFixed(2)}%):
                                </span>
                                <span>
                                  {formatBTCString((rentability.dailyProfit) / btcPrice)}
                                </span>
                              </div>
                              <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{t("refference")} (R$):</span>
                                <span>{numberFormatBRL(rentability.dailyProfit)}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    })}

                    {/* Saldo Final do Mês */}
                    <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', marginTop: '10px' }}>
                      {(dayjs(range.end).isSameOrBefore(dayjs())) && (
                        <span>{formatDate(range.end)} - {t("monthfinalbalance")}:</span>
                      )}
                      {(!dayjs(range.end).isSameOrBefore(dayjs())) && (
                        <span>{formatDate(dayjs())} - {t("currentbalance")}:</span>
                      )}

                      {(i === 0 && range.dailyReports.length === 0) && (
                        <span>{formatBTCString((investment.initialAmount) / btcPrice)}</span>
                      )}
                      {(i === 0 && range.dailyReports.length !== 0) && (
                        <span>{formatBTCString((range.dailyReports[range.dailyReports.length - 1]?.finalAmount) / btcPrice)}</span>
                      )}
                      {(i !== 0 && range.dailyReports.length !== 0) && (
                        <span>{formatBTCString((range.dailyReports[range.dailyReports.length - 1]?.finalAmount) / btcPrice)}</span>
                      )}
                      {(i !== 0 && range.dailyReports.length === 0) && (
                        <span>{formatBTCString((getLastMonthValue(ranges, i, investment.initialAmount)) / btcPrice)}</span>
                      )}
                    </div>

                    <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {(i === 0 && range.dailyReports.length === 0) && (
                        <>
                          <span>{t("refference")} (R$):</span>
                          <span>{numberFormatBRL(investment.initialAmount)}</span>
                        </>
                      )}
                      {(i === 0 && range.dailyReports.length !== 0) && (
                        <>
                          <span>{t("refference")} (R$):</span>
                          <span>{numberFormatBRL(range.dailyReports[range.dailyReports.length - 1]?.finalAmount)}</span>
                        </>
                      )}
                      {(i !== 0 && range.dailyReports.length !== 0) && (
                        <>
                          <span>{t("refference")} (R$):</span>
                          <span>{numberFormatBRL(range.dailyReports[range.dailyReports.length - 1]?.finalAmount)}</span>
                        </>
                      )}
                      {(i !== 0 && range.dailyReports.length === 0) && (
                        <>
                          <span>{t("refference")} (R$):</span>
                          <span>{numberFormatBRL(getLastMonthValue(ranges, i, investment.initialAmount))}</span>
                        </>
                      )}
                    </div>
                  </div>
                )
              ))}
            </div>
          )}
        </main>

        <footer className='pdf-footer' style={{ marginTop: '1500px' }}>
          <div className="title">
            <span>{t("fundinitiated")} {dayjs(investment.createdAt).format("DD/MM/YYYY")}</span>
            <span>{t("nextrenew")} {dayjs(investment.createdAt).add(investment.period, 'month').format("DD/MM/YYYY")}</span>
          </div>
          <p>
            {t("pdfwarning")}
          </p>
          <p>
            {t("refference")}: Coingecko <a href="https://www.coingecko.com">https://www.coingecko.com</a>
          </p>
        </footer>
      </div >
    </div >
  );
});

export default InvestmentPDF;
