import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import { Spinner, Button, Form } from "react-bootstrap";
import api, { microservice } from "../utils/api";
import { AppContext } from "../context/AppContext";
import { formatDate, formatDateDay } from "../utils/helper";
import dayjs from "dayjs";
import { findMonths, isDateInRange, findNewRanges } from "../utils/dateComparation";
import InvestmentModal from "./InvestmentModal";
import InvestmentWithdrawModal from "./InvestmentWithdrawModal";
import InvestmentWithdrawProfitModal from "./InvestmentWithdrawProfitModal";
import InvestmentPDF from "./InvestmentPDF";
import InvestmentRentabilityModal from "./InvestmentRentabilityModal";
import { formatBTCString } from "../utils/Helpers/btcFormat";
import { useTranslation, Trans } from 'react-i18next';
import '../i18n'
import { toast } from "react-toastify";

export const prepareValue = (value) => {
  return String(value).replace(".", ",");
}

export const numberFormatBRL = (value) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
}

export const removeSymbol = (value) => {
  return value.replace("R$", "").trim();
}

export const formatBRL = (value) => {
  // remove symbol
  if (value) {
    let stringVal = value;
    stringVal = stringVal.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    stringVal = stringVal.replace("R$", "").trim();

    return stringVal;
  }
};

function InvestmentIdAdmin() {
  const { id } = useParams();
  const {
    loggedInUser,
    showInvestmentModal,
    setShowInvestmentModal,
    showInvestmentWithdrawModal,
    setShowInvestmentWithdrawModal,
    showInvestmentWithdrawProfitModal,
    setShowInvestmentWithdrawProfitModal,
    showRentabilityModal,
    setShowRentabilityModal,
    prices,
    btcPrice,
    usdtPrice,
    usdPrice,
    setCountNotifications,
    setNotifications,
    trade,
  } = useContext(AppContext);

  const [selectedInvestment, setSelectedInvestment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ranges, setRanges] = useState(null);
  const [newRanges, setNewRanges] = useState([]);
  const [initialDate, setInitialDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [finalDate, setFinalDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [ddlWarning, setDdlWarning] = useState(dayjs().format("YYYY-MM-DD"));
  const [username, setUsername] = useState("");


  const pdf = useRef();

  const getUserDetails = async (userId) => {
    try {
      const response = await api.get(`users/${userId}`);
      if (response?.data) {
        const user = response.data;
        setUsername(user.name|| "Usuário desconhecido"); // Salva o username no estado
      } else {
        console.warn("Usuário não encontrado.");
      }
    } catch (error) {
      console.error("Erro ao buscar detalhes do usuário:", error);
    }
  };



  const getInvestment = async () => {
    const investment = await microservice.get(`investments/${id}`);
    if (investment) {
      setSelectedInvestment(investment);
      setLoading(false);
      getRanges(investment);
      sendNotification(investment);
      if (investment.userId) {
        await getUserDetails(investment.userId);
      }
    } else {
      console.log("investment not found")
      setLoading(false);
    }
  };

  const { t, i18n } = useTranslation();

  const filterDailyReports = (dailyReports, range) => {
    const dailyReportsinRange = dailyReports.filter((dr) => {
      const isInBetween = isDateInRange(dr.date, range);
      if (isInBetween) {
        return dr;
      } return null
    });

    range.dailyReports = dailyReportsinRange;
  }

  const getRanges = async (investment) => {
    const rangesArray = await findMonths((investment.createdAt), (investment.endDate), (investment.period + 1));
    if (rangesArray) {
      rangesArray.forEach((range) => {
        filterDailyReports(investment.dailyReports, range);
      });
      setRanges(rangesArray);
    } else {
      console.log("ranges not found")
    }
  };

  async function getWarningNotifications(days) {
    const today = new Date();
    const filterDate = new Date(today);
    filterDate.setDate(today.getDate() - 15); // Subtracting days from today

    const filterString = `filter[where][type]=warning-${days}-days&filter[where][createdAt][gte]=${filterDate.toISOString()}&filter[where][read]=false`;

    try {
      const response = await api.get(`users-notifications?${filterString}`);
      const data = response.data;
      // console.log('data', data.data);
      if (data.data?.length > 0) {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  const sendNotification = async (investment) => {

    const not45 = await getWarningNotifications(45);
    const not31 = await getWarningNotifications(31);

    const deadlineWarning = dayjs(investment.endDate).subtract(30, 'day').format('DD/MM/YYYY');
    const daysDifference = dayjs(investment.endDate).diff(dayjs(), 'day');
    setDdlWarning(daysDifference);

    const todayIsBeforeDDL = dayjs().isSameOrBefore(dayjs(investment.endDate).subtract(30, 'day'));
    const warning = investment.options.renewWarning;
    const todayIsAfterRenewWarning = dayjs().isSameOrAfter(dayjs(investment.options.renewWarning));

    // const todayIsAferDDL = dayjs().isAfter(dayjs(investment.endDate).subtract(15, 'day'));

    console.log("not45 >>", not45);
    console.log("not31 >>", not31);


    if (not45 === false && daysDifference <= 45 && daysDifference > 31) {

      const notification = {
        "event": t("investwithdrawwarning", { daysDifference, investmentId: investment._id, deadlineWarning }),
        "usersId": investment.userId,
        "type": "warning-45-days",
        "read": false,
      };


      if (warning && todayIsBeforeDDL && todayIsAfterRenewWarning) {
        const req = await api.post("users-notifications", notification);
        if (req) {
          setCountNotifications((countNotifications) => countNotifications + 1);
          setNotifications((notifications) => [...notifications, req.data]);

          toast.warning(t('endWarning'));

        }
      }
    }

    if (not31 === false && daysDifference < 31 && daysDifference >= 30) {
      const notification = {
        "event": t("investwithdrawwarning", { daysDifference, investmentId: investment._id, deadlineWarning }),
        "usersId": investment.userId,
        "type": "warning-31-days",
        "read": false,
      };

      if (warning && todayIsBeforeDDL && todayIsAfterRenewWarning) {
        const req = await api.post("users-notifications", notification);
        if (req) {
          setCountNotifications((countNotifications) => countNotifications + 1);
          setNotifications((notifications) => [...notifications, req.data]);

          toast.warning(t('endWarning'));

        }
      }
    }

    /*
    if(todayIsAferDDL){
      toast.warning(t('endWarning'));
    } */
  };

  const findRentability = (range) => {
    const filtered = range.dailyReports.filter((dr, i) =>
      // dayjs(dr.date).isSameOrBefore(dayjs(range.base))
      dr.appliedProfit == true
    )
    if (filtered.length !== 0) {
      const lastDr = filtered[filtered.length - 1];
      return (lastDr)
    } else {
      // console.log('there is no rentability')
      return null
    }
  }

  const getNewRanges = async () => {
    const rangesArray = await findNewRanges(initialDate, finalDate, (selectedInvestment?.createdAt));

    if (rangesArray.length > 0) {
      rangesArray.forEach((range) => {
        filterDailyReports(selectedInvestment?.dailyReports, range);
      });
      setNewRanges(rangesArray);
    }
  }

  const getLastMonthValue = (ranges, i, initialAmount) => {
    if (i !== 0) {
      const lastRange = ranges[i - 1];
      const dr = lastRange?.dailyReports[lastRange?.dailyReports?.length - 1];
      let finalAmount;
      if (dr == undefined) {
        finalAmount = initialAmount;
      } else {
        finalAmount = dr?.finalAmount;
      }
      return (finalAmount);
    }
    else return null;
  }

  useEffect(() => {
    getInvestment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getNewRanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDate, finalDate]);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" variant="warning" />
      </div>
    );
  }

  const currentAmount = (selectedInvestment?.dailyReports[selectedInvestment?.dailyReports?.length - 1])?.finalAmount;

  const anniversayFund = formatDateDay(selectedInvestment.createdAt);
  const today = formatDateDay(dayjs());
  const rentabilityDay = (today == anniversayFund + 1) // today can withdraw rentability

  const _exportPdf = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (pdf.current) {
      pdf.current.style.display = "block";
      const pdfDom = pdf.current.cloneNode(true);
      const style = document.createElement("style");
      style.setAttribute("id", "pdf-style");
      const response = fetch("/pdf.min.css");
      response.then((response) => response.text()).then((text) => {
        style.innerHTML = text;
        pdfDom.appendChild(style);

        // balances page-break
        const balances = Array.from(pdfDom.querySelectorAll(".monthly-balance"));
        if (document.querySelectorAll("div.page-break").length === 0) {
          balances.forEach((el, index) => {
            if (index !== 0) {
              const pageBreak = document.createElement("div");
              pageBreak.classList.add("page-break");
              el.insertAdjacentElement("beforebegin", pageBreak);
            }
          });
        }
        const pageBreaks = Array.from(pdfDom.querySelectorAll(".page-break"));
        pageBreaks.forEach((el) => (el.style.pageBreakBefore = "always"));
        const pdfContent = pdfDom.innerHTML;
        const iframe = document.createElement("iframe");
        iframe.setAttribute("id", "pdf-iframe");
        iframe.setAttribute("width", "0");
        iframe.setAttribute("height", "0");
        document.body.appendChild(iframe);
        const iframeWindow = iframe.contentWindow || iframe.contentDocument;
        const iframeDocument =
          iframeWindow.document || iframe.contentDocument;
        iframeDocument.open();
        iframeDocument.write(
          `<html>
    <head>
      <style>
        @media print {
          .page-break { page-break-before: always; }

          header {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 180px; /* Aumente a altura do cabeçalho se necessário */
            text-align: center;
            line-height: 20px;
            z-index: 5;
            border-bottom: 2px solid #ceba85;
          }

          body {
            margin-top: 200px; /* Compensa a altura do cabeçalho */
          }

          .footer {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            height: 50px;
            text-align: center;
            z-index: 5;
            font-size: 12px;
          }
        }
      </style>
      <title>BCX Investimento - ${formatDateDay(selectedInvestment.createdAt)}</title>
    </head>
    <body>
      <header>
        <div style="display: flex; justify-content: space-between; align-items: center; padding: 20px 50px;">
          <!-- Logo -->
          <div style="display: flex; align-items: center;">
            <img src='/images/logo.png' alt='bcx logo' style="max-width: 100px;" />
          </div>
          <!-- Central Information -->
          <div style="text-align: center; font-family: Arial, sans-serif;">
            <h2 style="margin: 0; font-size: 22px; font-weight: bold;">${username}</h2>
            <h3 style="margin: 0; font-size: 16px; font-weight: normal;">INFORME DE RENDIMENTO</h3>
            <p style="margin: 5px 0 0 0; font-size: 12px;">${formatDate(selectedInvestment.createdAt)} | Valor do BTC: ${btcPrice}</p>
          </div>
          <!-- Address -->
          <div style="text-align: right; font-family: Arial, sans-serif;">
            <p style="margin: 0; font-size: 12px;">Av. Osvaldo Reis</p>
            <p style="margin: 0; font-size: 12px;">3385 | Sala 1407</p>
            <p style="margin: 0; font-size: 12px;">Praia Brava</p>
            <p style="margin: 0; font-size: 12px;">Itajaí | SC</p>
            <p style="margin: 0; font-size: 12px;">CEP 88306-773</p>
            <p style="margin: 0; font-size: 12px;">+55 47 99128-0000</p>
            <p style="margin: 0; font-size: 12px;">www.bcxcorretora.com.br</p>
          </div>
        </div>
      </header>

      <div id='pdf'>
        <!-- Content goes here -->
        <div class='reports-trade-master'>
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br
          ${pdfContent}
        </div>
      </div>

      <footer class="footer">
        <p>Page <span class="pageNumber"></span> of <span class="totalPages"></span></p>
      </footer>
    </body>
  </html>`
        );
        iframeDocument.close();
        setTimeout(() => {
          iframe.focus();
          iframeWindow.print();
          pdf.current.style.display = "none";
          const pdfStyle = document.getElementById("pdf-style");
          pdfStyle.parentNode.removeChild(pdfStyle);
          // pageBreaks.forEach((el) => (el.style.pageBreakBefore = ""));
          document.body.removeChild(iframe);
        }, 1000);
      });
    }
  };

  return (
    <>
      <div className="inv-sum-title text-large2 bold text2">
        <span>{t("highcapinvestment")} nº {id}</span>
      </div>
      <div className="inv-id-buttons">
        {loggedInUser?.role !== "admin" && (
          <div>
            <Button onClick={() => setShowInvestmentModal(true)}>
              {t("deposit")} {/* Aportar */}
            </Button>
            {rentabilityDay ? (
              <Button onClick={() => setShowInvestmentWithdrawProfitModal(true)} className="ml-3">
                {t("withdraw")} {t("profit")} {/* Sacar Rentabilidade */}
              </Button>) : (<></>)}
            <Button onClick={() => setShowInvestmentWithdrawModal(true)} className="ml-3">
              {t("withdraw")} {/* Sacar */}
            </Button>
            <Button onClick={() => setShowRentabilityModal(true)} className="ml-3">
              {t("warnings")} {/* Avisos */}
            </Button>
          </div>
        )}
      </div>
      {selectedInvestment && (<>
        <div className="inv-sum-container">
          <div className="inv-id-div">
            <div className="inv-id-item">
              <span className="inv-id-item-title">
                {t("initialdepositdate")}:
              </span>
              <span className="inv-sum-item-text">
                {formatDate(selectedInvestment.createdAt)}
              </span>
            </div>
            <div className="inv-id-item">
              <span className="inv-id-item-title">
                {t("endinvdate")}:
              </span>
              <span className="inv-sum-item-text">
                {formatDate(selectedInvestment.endDate)}
              </span>
            </div>
            <div className="inv-id-item">
              <span className="inv-id-item-title">
                {t("period")}: {/* Período */}:
              </span>
              <span className="inv-sum-item-text">
                {selectedInvestment.period}   {t("months")}: {/* meses */}
              </span>
            </div>
            <div className="inv-id-item">
              <span className="inv-id-item-title">
                {t("fundbday")}: {/* Data do Aniversário do Fundo */}
              </span>
              <span className="inv-sum-item-text">
                {t("day")} {/* Dia */} {formatDateDay(selectedInvestment.createdAt)}
              </span>
            </div>
            <div className="inv-id-item">
              <span className="inv-id-item-title">
                {t("initialdepositvalue")}
              </span>
              <span className="inv-sum-item-text">
                {/* { prepareValue((selectedInvestment.initialAmount) / btcPrice) } */}
                {formatBTCString((selectedInvestment.initialAmount) / btcPrice)}
              </span>
              <div className="inv-sum-item-refBRL">
                <span>
                  {t("refference")} (R$): {numberFormatBRL(selectedInvestment.initialAmount)}
                </span>
              </div>
            </div>
            <div className="inv-id-item">
              <span className="inv-id-item-title">
                {t("investmenttype")} {/* Tipo de Investimento: */}
              </span>
              <span className="inv-sum-item-text">
                {selectedInvestment.modality} {selectedInvestment.type}
              </span>
            </div>
            <div className="inv-id-item">
              <span className="inv-id-item-title">
                {t("currentbalance")} {/* Saldo Atual: */}
              </span>
              {selectedInvestment.dailyReports.length !== 0 && (
                <>
                  <span className="inv-sum-item-text">
                    {/* { prepareValue((currentAmount) / btcPrice) } */}
                    {formatBTCString((currentAmount) / btcPrice)}
                  </span>
                  <div className="inv-sum-item-refBRL">
                    <span>
                      {t("refference")} (R$): {numberFormatBRL(currentAmount)}
                    </span>
                  </div>
                </>
              )}
              {selectedInvestment.dailyReports.length === 0 && (
                <>
                  <span className="inv-sum-item-text">
                    {formatBTCString((selectedInvestment.initialAmount) / btcPrice)}
                  </span>
                  <div className="inv-sum-item-refBRL">
                    <span>
                      {t("refference")} (R$): {numberFormatBRL(selectedInvestment.initialAmount)}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="justify-content-center inv-warningtext">*{t("rentabilitywarning")}</div>
        </div>
        <br></br>
        <div className="inv-sum-container" key={337}>

          {selectedInvestment && (
            <>
              <InvestmentModal showModal={showInvestmentModal} investment={selectedInvestment} />
              <InvestmentWithdrawModal showModal={showInvestmentWithdrawModal} investment={selectedInvestment} />
              <InvestmentWithdrawProfitModal showModal={showInvestmentWithdrawProfitModal} investment={selectedInvestment} />
              <InvestmentRentabilityModal showModal={showRentabilityModal} investment={selectedInvestment} deadlineWarning={ddlWarning} renewOption={selectedInvestment.options.renew} monthlyProfitWithdraw={selectedInvestment.options.monthlyProfitWithdraw} />
            </>
          )}

          {ranges?.map((range, i) => (<>
            {dayjs(range.start).isSameOrBefore(dayjs()) && (
              <>

                {/* // Título do Mês */}
                <span className="inv-id-months" key={i + 10}>
                  {t("month")} {/* Mês */} {i} -  <span id="inv-id-month-range">{formatDate(range.start)} {t("to")} {/* a */} {formatDate(range.end)}</span>
                </span>
                <div className="inv-id-line"></div>

                {/* // Saldo Inicial do Mês */}
                <div className="inv-id-statement">
                  <span className="inv-id-statement-text">{formatDate(range.start)} - {t("initialbalancemonth")} {/* Saldo Inicial do Mês: */} </span>
                  {(i === 0) &&
                    (<span className="inv-id-statement-text">
                      {/* BTC { prepareValue((selectedInvestment.initialAmount) / btcPrice) } */}
                      {formatBTCString((range.dailyReports.length === 0 ? selectedInvestment.initialAmount : range.dailyReports[0]?.initialAmount) / btcPrice)} {/* formatBTCString((selectedInvestment.initialAmount) / btcPrice) */}
                    </span>
                    )
                  }
                  {((i !== 0) && range.dailyReports.length > 0) && (<span className="inv-id-statement-text">
                    {/* BTC { prepareValue((getLastMonthValue(ranges, i)) / btcPrice) } */}
                    {formatBTCString((getLastMonthValue(ranges, i, selectedInvestment.initialAmount)) / btcPrice)}
                  </span>
                  )
                  }
                  {((i !== 0) && range.dailyReports.length === 0) && (<span className="inv-id-statement-text">
                    {/* BTC { prepareValue((getLastMonthValue(ranges, i)) / btcPrice) } */}
                    {formatBTCString((getLastMonthValue(ranges, i, selectedInvestment.initialAmount)) / btcPrice)}
                  </span>
                  )
                  }
                </div>

                <div className="inv-id-statement">
                  {(i === 0) &&
                    (<>
                      <span className="inv-id-refBRL">
                        {t("refference")} (R$):
                      </span>
                      <span className="inv-id-refBRL">
                        {numberFormatBRL(range.dailyReports.length === 0 ? selectedInvestment.initialAmount : range.dailyReports[0]?.initialAmount)}
                      </span>
                    </>
                    )
                  }
                  {((i !== 0) && range.dailyReports.length > 0) && (<>
                    <span className="inv-id-refBRL">
                      {t("refference")} (R$):
                    </span>
                    <span className="inv-id-refBRL">
                      {numberFormatBRL(getLastMonthValue(ranges, i, selectedInvestment.initialAmount))}
                    </span>
                  </>)
                  }
                  {((i !== 0) && range.dailyReports.length === 0) && (<>
                    <span className="inv-id-refBRL">
                      {t("refference")} (R$):
                    </span>
                    <span className="inv-id-refBRL">
                      {numberFormatBRL(getLastMonthValue(ranges, i, selectedInvestment.initialAmount))}
                    </span>
                  </>)
                  }

                </div>

                {/* // Acontecimentos do Mês */}
                {range.dailyReports?.map((dr, i) => {

// {/* // Saques */}
if (dr.withdraws.length !== 0) {
  return (
    dr.withdraws.map((w, i) => (
      w.status === 'approved' && (<>
        {w.type === 'liquidation' && (<>
          <div className="inv-id-statement">
            <span className="inv-id-statement-text">{formatDate(w.updatedAt)} - {t("totalAmount")}:</span>
            <span className="inv-id-statement-text">
              {formatBTCString((dr.initialAmount) / btcPrice)}
            </span>
          </div>
          <div className="inv-id-statement">
            <span className="inv-id-refBRL">
              {t("refference")} (R$):
            </span>
            <span className="inv-id-refBRL">
              {numberFormatBRL(dr.initialAmount)}
            </span>
          </div>
        </>)}
        <div className="inv-id-statement" key={i + 40}>
          <span className="inv-id-statement-text">{formatDate(w.updatedAt)} - {t("withdrawt")} {w.type === 'anticipated' ? t("anticipated") : w.type === 'liquidation' ? t("liquidate") : t("profit")}:</span>
          <span className="inv-id-statement-text">
            {formatBTCString((w.totalAmount) / btcPrice)}
          </span>
        </div>
        <div className="inv-id-statement">
          <span className="inv-id-refBRL">
            {t("refference")} (R$):
          </span>
          <span className="inv-id-refBRL">
            {numberFormatBRL(w.totalAmount)}
          </span>
        </div>
        <div className="inv-id-statement internal-statement">
          <span className="inv-id-statement-details">{t("operationalfee")}:</span>
          <span className="inv-id-statement-details">
            {formatBTCString((w.operatingFeeAmount) / btcPrice)}
          </span>
        </div>
        <div className="inv-id-statement internal-statement">
          <span className="inv-id-refBRL">
            {t("refference")} (R$):
          </span>
          <span className="inv-id-refBRL">
            {numberFormatBRL(w.operatingFeeAmount)}
          </span>
        </div>
        {w.type === 'anticipated' && (<>
          <div className="inv-id-statement internal-statement">
            <span className="inv-id-statement-details">{t("prorata")}:</span>
            <span className="inv-id-statement-details">
              {formatBTCString((w.proRataAmount) / btcPrice)}
            </span>
          </div>
          <div className="inv-id-statement internal-statement">
            <span className="inv-id-refBRL">
              {t("refference")} (R$):
            </span>
            <span className="inv-id-refBRL">
              {numberFormatBRL(w.proRataAmount)}
            </span>
          </div>
          <div className="inv-id-statement internal-statement">
            <span className="inv-id-statement-details">{t("penalty")}:</span>
            <span className="inv-id-statement-details">
              {formatBTCString((w.penaltyAmount) / btcPrice)}
            </span>
          </div>
          <div className="inv-id-statement internal-statement">
            <span className="inv-id-refBRL">
              {t("refference")} (R$):
            </span>
            <span className="inv-id-refBRL">
              {numberFormatBRL(w.penaltyAmount)}
            </span>
          </div>
        </>)}
        <div className="inv-id-statement internal-statement">
          <span className="inv-id-statement-details">{t("solicitedvalue")}:</span>
          <span className="inv-id-statement-details">
            {formatBTCString((w.requestedAmount) / btcPrice)}
          </span>
        </div>
        <div className="inv-id-statement internal-statement">
          <span className="inv-id-refBRL">
            {t("refference")} (R$):
          </span>
          <span className="inv-id-refBRL">
            {numberFormatBRL(w.requestedAmount)}
          </span>
        </div>
      </>)
    ))
  );
}

// {/* // Depósitos */}
if (dr.deposits.length !== 0) {
  return (dr.deposits.map((d, i) => (<>
    <div className="inv-id-statement" key={i + 30}>
      <span className="inv-id-statement-text">{formatDate(d.createdAt)} - {t("deposit")}:</span>
      <span className="inv-id-statement-text">
        {formatBTCString((d.amount) / btcPrice)}
      </span>
    </div>
    <div className="inv-id-statement">
      <span className="inv-id-refBRL">
        {t("refference")} (R$):
      </span>
      <span className="inv-id-refBRL">
        {numberFormatBRL(d.amount)}
      </span>
    </div>
  </>)))
}

// {/* // Rentabilidades - Exibe sempre, independentemente de appliedProfit */}
if (dayjs(range.base).isSameOrBefore(dayjs()) && findRentability(range)) {
return (<>
  <div className="inv-id-statement" key={i}>
    <span className="inv-id-statement-text">
      {formatDate(dr.date)} - {t("profit")} ({((dr.profitPercentage || 0) * 100).toFixed(2)}%):
    </span>
    <span className="inv-id-statement-text">
      {formatBTCString((dr.dailyProfit || 0) / btcPrice)}
    </span>
  </div>
  <div className="inv-id-statement">
    <span className="inv-id-refBRL">
      {t("refference")} (R$):
    </span>
    <span className="inv-id-refBRL">
      {numberFormatBRL(dr.dailyProfit || 0)}
    </span>
  </div>
</>);
}
})}


                {/* // Saldo Final do Mês */}
                <div className="inv-id-statement">
                  {(dayjs(range.end).isSameOrBefore(dayjs())) && (
                    <span className="inv-id-statement-text">{formatDate(range.end)} - {t("monthfinalbalance")} {/* Saldo Final do Mês */}:</span>
                  )}
                  {(!dayjs(range.end).isSameOrBefore(dayjs())) && (
                    <span className="inv-id-statement-text">{formatDate(dayjs())} - {t("currentbalance")} {/* Saldo Atual */}:</span>
                  )}

                  {/* // SF Valores BTC */}
                  {(i === 0) && (range.dailyReports.length === 0) && (<>
                    <span className="inv-id-statement-text">
                      {/* BTC { prepareValue((selectedInvestment.initialAmount) / btcPrice) } */}
                      {formatBTCString((selectedInvestment.initialAmount) / btcPrice)}
                    </span>
                  </>)}

                  {(i === 0) && (range.dailyReports.length !== 0) && (<>
                    <span className="inv-id-statement-text">
                      {/* BTC { prepareValue((range.dailyReports[range.dailyReports.length - 1]?.finalAmount) / btcPrice) } */}
                      {formatBTCString((range.dailyReports[range.dailyReports.length - 1]?.finalAmount) / btcPrice)}
                    </span>
                  </>)}

                  {(i !== 0) && (range.dailyReports.length !== 0) && (<>
                    <span className="inv-id-statement-text">
                      {/* BTC { prepareValue((range.dailyReports[range.dailyReports.length - 1]?.finalAmount) / btcPrice) } */}
                      {formatBTCString((range.dailyReports[range.dailyReports.length - 1]?.finalAmount) / btcPrice)}
                    </span>
                  </>)}
                  {(i !== 0) && (range.dailyReports?.length === 0) && (<>
                    <span className="inv-id-statement-text">
                      {/* BTC { prepareValue((getLastMonthValue(ranges, i)) / btcPrice) } */}
                      {formatBTCString((getLastMonthValue(ranges, i, selectedInvestment.initialAmount)) / btcPrice)}
                    </span>
                  </>)}
                </div>

                {/* // SF Referências BRL */}
                <div className="inv-id-statement" style={{ marginBottom: '15px' }}>
                  {(i === 0) && (range.dailyReports.length === 0) && (<>
                    <span className="inv-id-refBRL">
                      {t("refference")} (R$):
                    </span>
                    <span className="inv-id-refBRL">
                      {numberFormatBRL(selectedInvestment.initialAmount)}
                    </span>
                  </>)}

                  {(i === 0) && (range.dailyReports.length !== 0) && (<>
                    <span className="inv-id-refBRL">
                      {t("refference")} (R$):
                    </span>
                    <span className="inv-id-refBRL">
                      {numberFormatBRL(range.dailyReports[range.dailyReports.length - 1]?.finalAmount)}
                    </span>
                  </>)}

                  {(i !== 0) && (range.dailyReports.length !== 0) && (<>
                    <span className="inv-id-refBRL">
                      {t("refference")} (R$):
                    </span>
                    <span className="inv-id-refBRL">
                      {numberFormatBRL(range.dailyReports[range.dailyReports.length - 1]?.finalAmount)}
                    </span>
                  </>)}

                  {(i !== 0) && (range.dailyReports?.length === 0) && (<>
                    <span className="inv-id-refBRL">
                      {t("refference")} (R$):
                    </span>
                    <span className="inv-id-refBRL">
                      {numberFormatBRL(getLastMonthValue(ranges, i, selectedInvestment.initialAmount))}
                    </span>
                  </>)}
                </div>
              </>
            )}
          </>
          ))}
        </div>
      </>
      )}

      <InvestmentPDF ref={pdf} ranges={newRanges} investment={selectedInvestment} initialDate={initialDate} finalDate={finalDate} />

      {selectedInvestment && (
        <div className="reports">
          <span className="bold">{t("relatory")} {/* Relatório */}</span>
          <Form style={{ display: "flex", alignItems: "center" }}>
            <Form.Group className="ml-2 mr-2">
              <Form.Label className="bold mt-2 text-muted col-2">
                {t("from")} {/* De */}
              </Form.Label>
              <Form.Control
                className="input"
                type="date"
                value={initialDate}
                min={dayjs(selectedInvestment.dailyReports[0].date).format('YYYY/MM/DD')}
                max={dayjs(finalDate).format('YYYY-MM-DD')}
                onChange={(e) => setInitialDate(e.target.value)}
                style={{ width: 150 }}
              />
            </Form.Group>
            <Form.Group className="ml-2 mr-2">
              <Form.Label className="bold mt-2 text-muted">{t("unto")} {/* Até */}</Form.Label>
              <Form.Control
                className="input"
                type="date"
                value={finalDate}
                onChange={(e) => setFinalDate(e.target.value)}
                min={dayjs(initialDate).format('YYYY-MM-DD')}
                max={dayjs().format('YYYY-MM-DD')}
                style={{ width: 150 }}
              />
            </Form.Group>
            <Button
              className="btn mt-4 ml-2 mr-2"
              style={{ height: 35 }}
              onClick={() => _exportPdf()}
            >
              {t("download")} {/* Baixar */} PDF
            </Button>
          </Form>
          <span style={{ color: "black" }}>
            Data do Aporte Inicial (pré-renovações): {dayjs(selectedInvestment.dailyReports[0].date).format('DD-MM-YYYY')}
          </span>
        </div>
      )}
    </>
  )
}

export default InvestmentIdAdmin;
