import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Routes, Route, Navigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";

import { AppContext } from "./context/AppContext";
import { getToken, getUserDataFromJWT, isLogged } from "./utils/auth";
import api from "./utils/api";

import AdminHome from "./pages/Admin";
import AdminDeposits from "./pages/Admin/deposits";
import AdminNotifications from "./pages/Admin/Notifications";
import AdminWithdrawals from "./pages/Admin/withdraw";
import AdminApproveBTC from "./pages/Admin/ApproveBTC";
import AdminTwoFA from "./pages/Admin/twoFA";
import Maintenance from "./pages/Admin/maintenance";
import AdminWallets from "./pages/Admin/wallets";
import AdminHistory from "./pages/Admin/history";
import AdminCashBook from "./pages/Admin/cashBook";
import AdminTickets from "./pages/Admin/tickets";
import AdminManageProfitability from "./pages/Admin/manageProfitability";
import AdminManageFees from "./pages/Admin/manageFees";
import AdminBankRemittance from "./pages/Admin/bankRemittance";
import AdminKYC from "./pages/Admin/KYC";
import AdminEmployees from "./pages/Admin/employees";
import AdminEmployeeCreate from "./pages/Admin/create-employee";
import AdminUsers from "./pages/Admin/users";
import AdminUser from "./pages/Admin/user";
import AdminInvestments from "./pages/Admin/investments";
import AdminInvestment from "./pages/Admin/investment";

import NotFound from "./pages/NotFound.js";
import Home from "./pages/Home.js";
import About from "./pages/About.js";
import Info from "./pages/Info.js";
import Fees from "./pages/Fees.js";
import OpenSupport from "./pages/OpenSupport.js";
import FAQ from "./pages/FAQ.js";
import Signin from "./pages/Signin.js";
import RequestResetPassword from "./pages/RequestResetPassword.js";
import ResetPassword from "./pages/ResetPassword.js";
import Signup from "./pages/Signup.js";
import Logout from "./pages/Logout.js";
import Notifications from "./pages/Notifications";
import Dashboard from "./pages/Dashboard.js";
import Bot from "./pages/Bot.js";
import Trade from "./pages/Trade.js";
import ReferralUsers from "./pages/ReferralUsers.js";
import TradeHistory from "./pages/TradeHistory.js";
import Mining from "./pages/Mining.js";
import Deposits from "./pages/Deposits.js";
import Withdraw from "./pages/Withdraw.js";
import OTC from "./pages/OTC.js";
import TwoFA from "./pages/TwoFA.js";
import KYC from "./pages/KYC.js";
import History from "./pages/History.js";
import Suport from "./pages/Suport.js";
import Profile from "./pages/Profile.js";
import ConfirmCode from "./pages/ConfirmCode.js";
import ReceiveCrypto from "./pages/ReceiveCrypto";
import SendCrypto from "./pages/SendCrypto";
import DocHistory from "./pages/DocHistory";

import "./utils/style.css";
import "./utils/admin.css";
import "./utils/orderbook.css";
import "./utils/trade.css";
import "./utils/landingPage.css";
import "./utils/legal.css";
import Confirmations from "./pages/Confirmations";
import BuyBTC from "./components/BuyBTC";
import Orderbook from "./pages/Orderbook";
import TxHistory from "./pages/TxHistory";
import ClientInvestments from "./pages/ClientInvestments.js";
import ApproveWithdrawTrade from "./pages/Admin/ApproveWithdrawTrade";
import LandingPage from "./pages/LandingPage";
import user from "./pages/Admin/user";
import TradeId from "./pages/TradeId";
import AdminNewInvestment from "./pages/Admin/AdminNewInvestment";
import AdminNewProfitability from "./pages/Admin/AdminNewProfitability";
import Fares from "./pages/Legal/Fares";
import Privacy from "./components/Privacy";
import TermsPage from "./pages/Legal/TermsPage";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";
import PolicyKYC from "./pages/Legal/PoliticaKYC";
import InvestmentPolicy from "./pages/Legal/InvestmentPolicy";
import AdminProfitHistory from "./pages/Admin/AdminProfitHistory";
import Welcome from "./pages/Welcome";
import AdminDocHistory from "./pages/Admin/AdminDocHistory";
import AdmimReferralUsers from "./pages/Admin/AdmimReferralUsers.js";
import { useTranslation, Trans } from 'react-i18next';
import './i18n.js'

// git push update 

function Guest({ children }) {
  let location = useLocation();

  if (isLogged()) {
    const userObj = getUserDataFromJWT();

    if (userObj && userObj.role === "admin") {
      return <Navigate to="/panel/admin" state={{ from: location }} replace />;
    }

    return <Navigate to="/panel/trade" state={{ from: location }} replace />;
  }

  return children;
}

function RequireCustomerAuth({ children }) {
  let location = useLocation();
  const { t , i18n } = useTranslation();

  const { loggedInUser } = useContext(AppContext);
  const navigate = useNavigate();

  const conditionToShowAlert = (condition, toastText, link) => {
    if (condition) {
      toast.error(toastText, {
        closeButton: false,
        position: "top-right",
        theme: "colored",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        progress: undefined,
        onClick: () => {
          navigate(link);
        },
      });
    }
  };

  useEffect(() => {
    let condition = false;

    if (loggedInUser?.role === "user") {
      if (loggedInUser?.legalPerson) {
        condition =
          loggedInUser?.postalCode === "" ||
          loggedInUser?.cpf === "" ||
          loggedInUser?.cnpj === "" ||
          loggedInUser?.corporateName === "" ||
          loggedInUser?.tradingName === "";
      } else {
        condition = loggedInUser?.postalCode === "" || loggedInUser?.cpf === "";
      }
    }

    const text = t("finalizeregister");  // "Finalize seu cadastro para utilizar a plataforma";

    if (loggedInUser) {
      conditionToShowAlert(condition, text, "/panel/profile");
    }
  }, [loggedInUser]);

  useEffect(() => {
    const kycStatus = loggedInUser && loggedInUser.kycStatus;

    const condition =
      loggedInUser?.role === "user" &&
      (loggedInUser?.postalCode !== "" || loggedInUser?.cpf !== "");

    let text;

    if (kycStatus === "none") {
      text = t("needsenddoc");  // "Envie seus documentos para validarmos seu KYC!"
      conditionToShowAlert(condition, text, "/panel/kyc");
      return () => null;
    }

    return toast.dismiss();
  }, [loggedInUser?.kycStatus]);

  useEffect(() => {
    if (loggedInUser) {
      if (
        loggedInUser &&
        loggedInUser.role === "user" &&
        !loggedInUser.emailConfirm
      ) {
        toast.info(t("confirmnewemail"), {  // "Confirme o seu novo email!"
          closeButton: false,
          position: "top-right",
          theme: "colored",
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          progress: false,
          onClick: () => {
            navigate("/panel/kyc");
          },
        });
      }

      if (
        loggedInUser &&
        loggedInUser.role === "user" &&
        !loggedInUser.phoneConfirm
      ) {
        toast.info(t("confirmyourphone"), {  // ""Confirme seu número de telefone!"" 
          closeButton: false,
          position: "top-right",
          theme: "colored",
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          progress: false,
          onClick: () => {
            navigate("/panel/kyc");
          },
        });
      }
    }
  }, [loggedInUser]);

  if (!isLogged()) {
    return <Navigate to="/panel/signin" state={{ from: location }} replace />;
  }

  return children;
}

function RequireAdmin({ children }) {
  let location = useLocation();

  const userObj = getUserDataFromJWT();

  if (userObj && userObj.role === "admin") {
    return <RequireCustomerAuth>{children}</RequireCustomerAuth>;
  }

  return <Navigate to="/panel/signin" state={{ from: location }} replace />;
}

function RequireKycLock({ children }) {
  const { loggedInUser } = useContext(AppContext);

  let location = useLocation();

  if (loggedInUser?.kycStatus !== "none") {
    return <RequireCustomerAuth>{children}</RequireCustomerAuth>;
  }

  return <Navigate to="/panel/kyc" state={{ from: location }} replace />;
}

function App() {
  
  const { t, i18n } = useTranslation();


  const {
    loggedInUser,
    setLoggedInUser,
    setNotifications,
    setLegalPerson,
    getAllInvestments,
    getBalances,
    getInvestment,
    setCountNotifications,
    setCountPendingStatus,
    getFees,
    getSeeInvestment,
    getSeeReferal,
    getReferralCode,
    setCompleteUser,
    maintenanceMode
  } = useContext(AppContext);

  const token = getToken();
  const navigate = useNavigate();

  const logoutUser = () => {
    console.log("TEMPO EXPIRADO! logout sendo feito");
    localStorage.removeItem("token");
    // setLoggedInUser(null);
    navigate("/panel/signin");
  }

  // automatic logout user after inactivity
  useEffect(() => {
    let inactivityTimer;

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(logoutUser, 300000); // 5 minutes 300000
      // inactivityTimer = setTimeout(logoutUser, 10000); // 10 seconds
    };

    const handleUserActivity = () => {
      resetTimer();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('mousedown', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);
  }, []);


  useEffect(() => {
    const userObj = getUserDataFromJWT();

    if (token) {
      api
        .get(
          `users-notifications/count?where[read]=false&usersId=${userObj?.id}`
        )
        .then((req) => {
          setCountNotifications(req.data.count);
        });

      api.get(`users/${userObj?.id}`).then((req) => {
        if (!req.error) {
            setCompleteUser(req.data)
          }
        });
    }
  }, [token]);

  useEffect(() => {
    const userObj = getUserDataFromJWT();
    if (token && userObj && userObj.role === "admin") {
      getCountPendingStatus();
    }
  }, [token]);

  useEffect(() => {
    if (token && !loggedInUser) {
      api.get(`me`).then((req) => {
        if (!req.error) {
          setLoggedInUser(req.data);
          setNotifications(req.data.notifications);
          setLegalPerson(req.data.legalPerson);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isLogged()) {
      const userObj = getUserDataFromJWT();
      getInvestment();
      getFees();
      getBalances(userObj.id);
      getAllInvestments(userObj.id);
      getSeeInvestment(userObj.id);
      getSeeReferal(userObj.id);
      getReferralCode(userObj.id);
      // console.log(userObj.id);
    }
  }, [loggedInUser]);

  const getCountPendingStatus = async () => {
    await api.get("stats").then((req) => {
      if (!req.error) {
        setCountPendingStatus(req.data);
      }
    });
  };

  return (
    <>
      <Routes>
        <Route
          exact
          path="/oldPage"
          element={
            <Guest>
              <Home />
            </Guest>
          }
        />
        <Route
          exact
          path="/"
          element={
            <Guest>
              <LandingPage />
            </Guest>
          }
        />
        <Route
          exact
          path="/tarifas"
          element={
            <Guest>
              <Fares/>
            </Guest>
          }
        />
        <Route
          exact
          path="/politica-de-privacidade-exchange"
          element={
            <Guest>
              <PrivacyPolicy/>
            </Guest>
          }
        />
        <Route
          exact
          path="/termos-de-uso-exchange"
          element={
            <Guest>
              <TermsPage/>
            </Guest>
          }
        />
        <Route
          exact
          path="/panel/signin"
          element={
            <Guest>
              <Signin />
            </Guest>
          }
        />
        <Route
          exact
          path="/panel/recover"
          element={
            <Guest>
              <RequestResetPassword />
            </Guest>
          }
        />
        <Route
          exact
          path="/panel/reset"
          element={
            <Guest>
              <ResetPassword />
            </Guest>
          }
        />
        <Route
          exact
          path="/panel/signup/"
          element={
            <Guest>
              <Signup />
            </Guest>
          }
        />
        <Route
          exact
          path="/panel/about"
          element={
            <RequireCustomerAuth>
              <About />
            </RequireCustomerAuth>
          }
        />
        <Route
          exact
          path="/panel/info"
          element={
            <RequireCustomerAuth>
              <Info />
            </RequireCustomerAuth>
          }
        />
        <Route
          exact
          path="/panel/tarifas"
          element={
            <RequireCustomerAuth>
              <Fares />
            </RequireCustomerAuth>
          }
        />
        <Route
          exact
          path="/panel/open_support"
          element={
            <RequireCustomerAuth>
              <OpenSupport />
            </RequireCustomerAuth>
          }
        />
        <Route
          exact
          path="/panel/faq"
          element={
            <RequireCustomerAuth>
              <FAQ />
            </RequireCustomerAuth>
          }
        />
        <Route exact path="/panel/confirm" element={<ConfirmCode />} />
        {/* KYC role user */}
        <>
          <Route
            exact
            path="/panel/trade"
            element={
              <RequireKycLock>
                <Trade />
              </RequireKycLock>
            }
          />
          <Route
            exact
            path="/panel/referral"
            element={
              <RequireKycLock>
                <ReferralUsers />
              </RequireKycLock>
            }
          />
          <Route
            exact
            path="/panel/doc_history"
            element={
              <RequireKycLock>
              <DocHistory />
            </RequireKycLock>
            }
          />
          <Route
            exact
            path="/panel/trade/:id"
            element={
              <RequireKycLock>
                <TradeId />
              </RequireKycLock>
            }
          />
          <Route
            exact
            path="/panel/trade_hist"
            element={
              <RequireKycLock>
                <TradeHistory />
              </RequireKycLock>
            }
          />

          <Route
            exact
            path="/panel/receive"
            element={
              <RequireKycLock>
                <ReceiveCrypto />
              </RequireKycLock>
            }
          />

          <Route
            exact
            path="/panel/send"
            element={
              <RequireKycLock>
                <SendCrypto />
              </RequireKycLock>
            }
          />

          <Route
            exact
            path="/panel/confirmations"
            element={
              <RequireKycLock>
                <Confirmations />
              </RequireKycLock>
            }
          />

          <Route
            exact
            path="/panel/deposits"
            element={
              <RequireKycLock>
                <Deposits />
              </RequireKycLock>
            }
          />
          <Route
            exact
            path="/panel/orderbook"
            element={
              <RequireKycLock>
                <Orderbook />
              </RequireKycLock>
            }
          />

          <Route
            exact
            path="/panel/tx_history"
            element={
              <RequireKycLock>
                <TxHistory />
              </RequireKycLock>
            }
          />

          <Route
              exact
              path="/panel/client_tx_history/:id"
              element={
                <RequireKycLock>
                  <ClientInvestments />
                </RequireKycLock>
              }
            />

          <Route
            exact
            path="/panel/withdraw"
            element={
              <RequireKycLock>
                <Withdraw />
              </RequireKycLock>
            }
          />
        </>

        {/* role user */}
        <>
          <Route
            exact
            path="/panel/logout"
            element={
              <RequireCustomerAuth>
                <Logout />
              </RequireCustomerAuth>
            }
          />
          <Route
            exact
            path="/panel/dashboard"
            element={
              <RequireCustomerAuth>
                <Dashboard />
              </RequireCustomerAuth>
            }
          />
          <Route
            exact
            path="/panel/notifications"
            element={
              <RequireCustomerAuth>
                <Notifications />
              </RequireCustomerAuth>
            }
          />
          <Route
            exact
            path="/panel/bot"
            element={
              <RequireCustomerAuth>
                <Bot />
              </RequireCustomerAuth>
            }
          />
          <Route
            exact
            path="/panel/mining"
            element={
              <RequireCustomerAuth>
                <Mining />
              </RequireCustomerAuth>
            }
          />

          <Route
            exact
            path="/panel/otc"
            element={
              <RequireCustomerAuth>
                <OTC />
              </RequireCustomerAuth>
            }
          />
          <Route
            exact
            path="/panel/kyc"
            element={
              <RequireCustomerAuth>
                <KYC />
              </RequireCustomerAuth>
            }
          />
          <Route
            exact
            path="/panel/politica-kyc"
            element={
              <RequireCustomerAuth>
                <PolicyKYC />
              </RequireCustomerAuth>
            }
          />
          <Route
            exact
            path="/panel/politica-de-privacidade-produto-inevstimento"
            element={
              <RequireCustomerAuth>
                <InvestmentPolicy />
              </RequireCustomerAuth>
            }
          />
          <Route
            exact
            path="/panel/twofa"
            element={
              <RequireCustomerAuth>
                <TwoFA />
              </RequireCustomerAuth>
            }
          />
          <Route
            exact
            path="/panel/history"
            element={
              <RequireCustomerAuth>
                <History />
              </RequireCustomerAuth>
            }
          />
          <Route
            exact
            path="/panel/support"
            element={
              <RequireCustomerAuth>
                <Suport />
              </RequireCustomerAuth>
            }
          />
          <Route
            exact
            path="/panel/profile"
            element={
              <RequireCustomerAuth>
                <Profile />
              </RequireCustomerAuth>
            }
          />
          <Route
            exact
            path="/panel/bem-vindo"
            element={
              <RequireCustomerAuth>
                <Welcome />
              </RequireCustomerAuth>
            }
          />
        </>

        {/* role admin */}
        {
          <>
            <Route
              exact
              path="/panel/admin"
              element={
                <RequireAdmin>
                  <AdminHome />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/notifications"
              element={
                <RequireAdmin>
                  <AdminNotifications />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/deposits"
              element={
                <RequireAdmin>
                  <AdminDeposits />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/withdraw"
              element={
                <RequireAdmin>
                  <AdminWithdrawals />
                </RequireAdmin>
              }
            />

            <Route
              exact
              path="/panel/admin/approveWithdrawTrade"
              element={
                <RequireAdmin>
                  <ApproveWithdrawTrade />
                </RequireAdmin>
              }
            />

            <Route
              exact
              path="/panel/admin/historyBTC"
              element={
                <RequireAdmin>
                  <AdminApproveBTC />
                </RequireAdmin>
              }
            />

            <Route
              exact
              path="/panel/admin/kyc"
              element={
                <RequireAdmin>
                  <AdminKYC />
                </RequireAdmin>
              }
            />

            <Route
              exact
              path="/panel/admin/doc-history"
              element={
                <RequireAdmin>
                  <AdminDocHistory/>
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/twofactor"
              element={
                <RequireAdmin>
                  <AdminTwoFA />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/maintenance"
              element={
                <RequireAdmin>
                  <Maintenance />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/wallets"
              element={
                <RequireAdmin>
                  <AdminWallets />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/history"
              element={
                <RequireAdmin>
                  <AdminHistory />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/cash-book"
              element={
                <RequireAdmin>
                  <AdminCashBook />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/cadastro-rentabilidades"
              element={
                <RequireAdmin>
                  <AdminManageProfitability />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/historico-rentabilidades"
              element={
                <RequireAdmin>
                  <AdminProfitHistory />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/manage-fees"
              element={
                <RequireAdmin>
                  <AdminManageFees />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/bank-remittance"
              element={
                <RequireAdmin>
                  <AdminBankRemittance />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/tickets"
              element={
                <RequireAdmin>
                  <AdminTickets />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/employees"
              element={
                <RequireAdmin>
                  <AdminEmployees />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/employees/create"
              element={
                <RequireAdmin>
                  <AdminEmployeeCreate />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/users"
              element={
                <RequireAdmin>
                  <AdminUsers />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/user/:id"
              element={
                <RequireAdmin>
                  <AdminUser />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/investments"
              element={
                <RequireAdmin>
                  <AdminInvestments />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/investment/:id"
              element={
                <RequireAdmin>
                  <AdminInvestment />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/investment/new/:id"
              element={
                <RequireAdmin>
                  <AdminNewInvestment />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/cadastro-rentabilidade"
              element={
                <RequireAdmin>
                  <AdminNewProfitability />
                </RequireAdmin>
              }
            />
            <Route
              exact
              path="/panel/admin/usersreferral/:id"
              element={
                <RequireAdmin>
                  <AdmimReferralUsers />
                </RequireAdmin>
              }
            />
          </>
        }
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
      <ToastContainer />
      <BuyBTC />
    </>
  );
}
export default App;
